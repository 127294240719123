<template>
	<main class="main">
		<div class="personal">
			<div class="container personal-inner">
				<div class="row">
					<div class="personal-cnt col-md-4 offset-md-4">

						<a class="personal-logo" href="#" :class="{'personal-logo--mb' : data_exists && !errorMessage}">
							<img src="../../../../../shared/assets/styles/images/logo.svg" alt="logo">
						</a>

						<validation-observer ref="observer" v-slot="{ handleSubmit }">

							<div v-if="!data_exists">
								<div class="invalid_feedback mb-2">Invalid Token Verification. </div>
								<p class="personal-links">
									<router-link :to="{name: 'app.login'}">Back to Sign In</router-link>
								</p>
							</div>
							<div v-if="errorMessage" >
								<div class="invalid_feedback mb-2">{{errorMessage}}</div>
								<p class="personal-links">
									<router-link :to="{name: 'app.login'}">Back to Sign In</router-link>
								</p>
							</div>


							<b-form  v-if="verified" class="personal-form" @submit.stop.prevent="handleSubmit(resetPassword)">

								<ValidationProvider rules="required|min:6|max:18" name="Password" vid="password">
									<b-form-group
											slot-scope="{ errors }"
											label="New password"
											label-for="password">
										<b-form-input
												type="password"
												id="password"
												v-model="password"
												:state="errors[0] ? false : null"
												placeholder="New password">
										</b-form-input>
										<span class="pass-btn js-pass-btn"
											  @click="switchVisibility($event, 'password')">
										</span>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<ValidationProvider rules="required|confirmed:password" name="New password"
													vid="password_confirmation">
									<b-form-group
											slot-scope="{ errors }"
											label="Confirm new password"
											label-for="password_confirmation">
										<b-form-input
												id="password_confirmation"
												type="password"
												v-model="password_confirmation"
												:state="errors[0] ? false : null"
												placeholder="Confirm new password">
										</b-form-input>
										<span class="pass-btn js-pass-btn"
											  @click="switchVisibility($event, 'password_confirmation')">
										</span>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<div class="form-group">
									<input class="btn btn-primary" type="submit" value="Save Password">
								</div>
								<p class="personal-links">
									<router-link class="personal-links" :to="{name: 'app.login'}">Back to Sign In</router-link>
								</p>
							</b-form>
						</validation-observer>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
    import {authHelperMixin} from "../../../../../shared/mixins/auth-mixin";

    export default {
        name: "ResetPassword",
        mixins: [authHelperMixin],
        data() {
            return {
                password: '',
                password_confirmation: '',
                token: false,
                user_id: false,
                verified: false,
                errorMessage: false

            }
        },
        methods: {
            resetPassword() {
                let sendData = {
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    user_id: this.user_id,
                    token: this.token
                };
                return this.$store.dispatch('auth/resetPassword', sendData).then(() => {
                    this.$router.push('/')
                }).catch((err) => {
                    this.showErrorNotifications(err);
                    this.password = '';
                    this.password_confirmation = '';

                    this.$nextTick(() => {
                        this.$refs.observer.setErrors({});
                        this.$nextTick(() => {
                            this.$refs.observer.reset();
                        });
                    });
                })
            }
        },
        computed: {
            data_exists() {
                return this.token && this.user_id !== false
            }
        },
        created() {
            this.token = this.$route.query.token || false;
            this.user_id = this.$route.query.user_id || false;
        },
        mounted() {
            if (this.data_exists) {
                let sendData = {
                    user_id: this.user_id,
					token: this.token
                };
                return this.$store.dispatch('auth/verifyToken', sendData).then((response) => {
                    if (response.token === this.token) {
                        this.verified = true;
                    }
                }).catch((err) => {
                    this.errorMessage = err.response.data.message;
                })
            }
        }
    }
</script>
<style lang="scss">
	.invalid_feedback {
		display: flex;
		justify-content: center;

		width: 100%;
		margin-top: 0.25rem;
		color: #dc3545;
	}

	@import "../../../../../shared/assets/styles/blocks/personal";
</style>
