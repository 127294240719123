export const authHelperMixin = {
    methods: {
        switchVisibility($event, passwordInputId) {
            const passwordField = document.getElementById(passwordInputId);

            if (passwordField.getAttribute('type') === 'password') {
                passwordField.setAttribute('type', 'text');
                $event.target.classList.add('show');

            }   else {
                passwordField.setAttribute('type', 'password')
                $event.target.classList.remove('show');
            }
        },
    }
}
